import { useState } from 'react'
import Button from './Button'

const CookieModal = () => {
  const [isVisible, setVisible] = useState(() => {
    return typeof window === 'undefined' ? false : localStorage.getItem('hide_cookie_banner') !== 'true'
  })

  const hideBanner = () => {
    setVisible(false)
    localStorage.setItem('hide_cookie_banner', 'true')
  }

  if (!isVisible) {
    return null
  }

  return (
    <div z="999" pos="fixed bottom-0 left-0 right-0" display="flex" p="4">
      <div
        display="flex"
        flex="<tablet:col"
        bg="white dark:dark-800"
        text="black"
        m="auto b-4"
        w="225"
        p="x-4 y-8"
        border="rounded-md"
      >
        <p m="r-4" text="black dark:white">
          {`
            Our website uses cookies to ensure you get the best experience on our
            website - and don't worry, they're zero calories! See our
          `}
          <a href="/privacy-policy" font="bold"> privacy policy</a> for more info.
        </p>
        <div display="flex" m="<tablet:t-4">
          <Button className="m-auto dark:bg-rh-navy-light dark:text-black" onClick={hideBanner}>
            Got it!
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CookieModal
