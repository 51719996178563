import Button, { ButtonProps } from 'WindiComponents/Button'
import Icon from '@mdi/react'

interface Props extends ButtonProps {
  icon?: string
  size?: number
  className?: string
}

const IconButton = (props: Props) => {
  const {
    icon,
    variant,
    size = 32,
    className = '',
    ...rest
  } = props

  return (
    <Button {...rest} variant={variant} className={`${variant === 'transparent' ? '!p-0' : '!p-2'} ${className}`}>
      <Icon
        path={icon}
        style={{ height: `${size}px` }}
      />
    </Button>
  )
}

export default IconButton
