import { getColorClass } from 'Helpers/theme'
import { RHColor, RHColorVariant, RHFontSize } from 'Types/theme'

type SpanProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>
type HeaderProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>
type NativeProps = SpanProps | HeaderProps

type Props = NativeProps & {
  variant?: RHFontSize
  color?: RHColor
  colorVariant?: RHColorVariant
}

const NativeSpan = (props: SpanProps) => <span {...props} />
const NativeH1 = (props: HeaderProps) => <h1 {...props} />
const NativeH2 = (props: HeaderProps) => <h2 {...props} />
const NativeH3 = (props: HeaderProps) => <h3 {...props} />
const NativeH4 = (props: HeaderProps) => <h4 {...props} />
const NativeH5 = (props: HeaderProps) => <h5 {...props} />
const NativeH6 = (props: HeaderProps) => <h6 {...props} />

const getNativeComponent = (variant: RHFontSize) => {
  if (variant === 'h1') {
    return NativeH1
  } else if (variant === 'h2') {
    return NativeH2
  } else if (variant === 'h3') {
    return NativeH3
  } else if (variant === 'h4') {
    return NativeH4
  } else if (variant === 'h5') {
    return NativeH5
  } else if (variant === 'h6') {
    return NativeH6
  }

  return NativeSpan
}

const Typography = (props: Props) => {
  const {
    variant = 'body',
    color,
    colorVariant,
    className = '',
    ...rest
  } = props

  const NativeComponent = getNativeComponent(variant)

  return (
    <NativeComponent
      className={`
        ${color ? `text-${getColorClass(color, colorVariant)}` : ''}

        ${variant === 'display' ? 'text-rh-display font-extrabold' : ''}
        ${variant === 'h1' ? 'text-rh-h1 font-bold' : ''}
        ${variant === 'h2' ? 'text-rh-h2 font-bold' : ''}
        ${variant === 'h3' ? 'text-rh-h3 font-bold' : ''}
        ${variant === 'h4' ? 'text-rh-h4 font-bold' : ''}
        ${variant === 'h5' ? 'text-rh-h5 font-bold' : ''}
        ${variant === 'h6' ? 'text-rh-h6 font-bold' : ''}
        ${variant === 'capitalized' ? 'text-rh-capitalized font-semibold uppercase' : ''}
        ${variant === 'body' ? 'text-rh-body font-normal' : ''}
        ${variant === 'body-small' ? 'text-rh-bodysmall font-normal' : ''}
        ${variant === 'body-smaller' ? 'text-rh-bodysmaller font-normal' : ''}
        ${variant === 'callout' ? 'text-rh-callout font-medium' : ''}

        ${className}
      `}
      {...rest}
    />
  )
}

const createVariantComponent = (variant: RHFontSize) => {
  const VariantComponent = (props: Omit<Props, 'variant'>) => <Typography {...props} variant={variant} />
  return VariantComponent
}

export const H1 = createVariantComponent('h1')
export const H2 = createVariantComponent('h2')
export const H3 = createVariantComponent('h3')
export const H4 = createVariantComponent('h4')
export const H5 = createVariantComponent('h5')
export const H6 = createVariantComponent('h6')

export const BodyText = createVariantComponent('body')
export const SmallBodyText = createVariantComponent('body-small')
export const SmallerBodyText = createVariantComponent('body-smaller')

export default Typography
