import { ButtonHTMLAttributes, DetailedHTMLProps, useState } from 'react'
import { RHColor } from 'Types/theme'

type NativeButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

export interface ButtonProps extends NativeButtonProps {
  variant?: 'solid' | 'transparent'
  color?: RHColor
  onClick?: () => (any | Promise<any>)
}

const Button = (props: ButtonProps) => {
  const {
    variant = 'solid',
    color = 'red',
    onClick,
    children,
    className = '',
    ...rest
  } = props

  const [isLoading, setLoading] = useState(false)

  const wrappedOnClickHandler = () => {
    if (!onClick) {
      return
    }

    const result = onClick()

    if (result instanceof Promise) {
      setLoading(true)
      return result.finally(() => setLoading(false))
    }

    return result
  }

  return (
    <button
      {...rest}
      onClick={wrappedOnClickHandler}
      disabled={isLoading}
      p="y-2 x-4"
      font="sans"
      text="rh-button"
      pos="relative"
      transition="all duration-duration-300"
      className={`
        whitespace-nowrap

        ${variant !== 'solid' ? '' : `
          ${color === 'navy' ? 'bg-rh-navy hover:bg-rh-navy-dark text-white' : ''}
          ${color === 'red' ? 'bg-rh-red hover:bg-rh-red-dark text-white' : ''}
          ${color === 'orange' ? 'bg-rh-orange hover:bg-rh-orange-light text-black' : ''}
          ${color === 'yellow' ? 'bg-rh-yellow hover:bg-rh-yellow-light text-black' : ''}
          ${color === 'peach' ? 'bg-rh-peach hover:bg-rh-peach-light text-black' : ''}
          ${color === 'green' ? 'bg-rh-green hover:bg-rh-green-light text-black' : ''}
          ${color === 'blue' ? 'bg-rh-blue hover:bg-rh-blue-light text-black' : ''}
          ${color === 'grey' ? 'bg-rh-grey hover:bg-rh-grey-dark text-black' : ''}
        `}

        ${variant !== 'transparent' ? '' : `
          bg-transparent
          ${color === 'navy' ? 'text-rh-navy hover:text-rh-navy-dark' : ''}
          ${color === 'red' ? 'text-rh-red hover:text-rh-red-dark' : ''}
          ${color === 'orange' ? 'text-rh-orange hover:text-rh-orange-light' : ''}
          ${color === 'yellow' ? 'text-rh-yellow hover:text-rh-yellow-light' : ''}
          ${color === 'peach' ? 'text-rh-peach hover:text-rh-peach-light' : ''}
          ${color === 'green' ? 'text-rh-green hover:text-rh-green-light' : ''}
          ${color === 'blue' ? 'text-rh-blue hover:text-rh-blue-light' : ''}
          ${color === 'grey' ? 'text-rh-grey hover:text-rh-grey-dark' : ''}
        `}

        ${className}
      `}
    >
      <div display="flex" m="auto" style={{ visibility: isLoading ? 'hidden' : undefined }}>
        {children}
      </div>
      {isLoading && (
        <div
          pos="absolute top-0 right-0 bottom-0 left-0"
          className={`
            ${variant !== 'solid' ? '' : `
              ${color === 'navy' ? 'bg-rh-navy-dark' : ''}
              ${color === 'red' ? 'bg-rh-red-dark' : ''}
              ${color === 'orange' ? 'bg-rh-orange-light' : ''}
              ${color === 'yellow' ? 'bg-rh-yellow-light' : ''}
              ${color === 'peach' ? 'bg-rh-peach-light' : ''}
              ${color === 'green' ? 'bg-rh-green-light' : ''}
              ${color === 'blue' ? 'bg-rh-blue-light' : ''}
              ${color === 'grey' ? 'bg-rh-grey-dark' : ''}
            `}
          `}
        >
          <div h="full" display="flex">
            <i
              m="auto auto"
              className="fa fa-spinner"
              aria-hidden="true"
              animate="spin duration-2000"
            />
          </div>
        </div>
      )}
    </button>
  )
}

export default Button
