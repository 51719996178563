import { useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import Layout from 'Components/Layout'
import PrismicImageProvider from 'Components/image/PrismicImageProvider'
import GlobalStyle from 'StyleGuide/globalStyle'
import cartStore from 'Store/cartStore'
import PageFrame from 'WindiComponents/page/PageFrame'
import CookieModal from 'WindiComponents/CookieModal'
import CookieNotice from 'Components/CookieNotice'
import Head from 'next/head'
import * as theme from 'StyleGuide/theme'
import * as Sentry from '@sentry/browser'

// eslint-disable-next-line no-restricted-imports
import '../index.css'

// global styled need to be in the _app.js file
import 'windi.css'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'

// routes registered here will be rendered using the new page frame created in windicss
// you must wrap your page manually in the PageFrame component
// all components MUST be using windicss to prevent style bleed
// this should be removed one all routes are using the new page frame
const V2_ROUTES = [
  '/_components',
  '/_test',
]

const AppProvider = ({ children }: any) => {
  return <>{children}</>
}

// When we implement dark mode, the theme will need to pass
// either .light or .dark class depending on state
const DefaultPageWrapper = (props: any) => {
  return <PageFrame {...props} className="light" />
}

const MyApp = ({ Component, pageProps, router }: any) => {
  const { loadCart, items } = cartStore((state) => ({
    loading: state.loading,
    items: state.items,
    loadCart: state.loadCart,
  }))

  useEffect(() => {
    loadCart().catch(e => Sentry.captureException(e))
  }, [])

  if (V2_ROUTES.includes(router.route)) {
    const Wrapper = Component.frame ?? DefaultPageWrapper

    return (
      <>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Head>
        <Wrapper>
          <Component {...pageProps} />
          <CookieModal />
        </Wrapper>
      </>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <AppProvider>
        <PrismicImageProvider debounce={500}>
          <GlobalStyle />
          <CookieNotice />
          <Head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0"
            />
          </Head>
          <Layout mastStyle={Component?.mastStyle} items={items}>
            <Component {...pageProps} />
          </Layout>
        </PrismicImageProvider>
      </AppProvider>
    </ThemeProvider>
  )
}

export default MyApp
