
export const NAV_BAR_ITEMS = [
  {
    key: 'pricing',
    name: 'Pricing',
    link: '/pricing',
  },
  {
    key: 'transformations',
    name: 'Transformations',
    link: '/transformations',
  },
  {
    key: 'about',
    name: 'About',
    link: '/about',
  },
  {
    key: 'blog',
    name: 'Blog',
    link: '/blog',
  },
  {
    key: 'shop',
    name: 'Shop',
    link: '/store',
  },
]
