import { mdiCart, mdiMenu } from '@mdi/js'
import { ComponentType } from 'react'
import Button from 'WindiComponents/Button'
import IconButton from 'WindiComponents/IconButton'
import DesktopNavBar from './DesktopNavBar'

interface Props {
  onOpenMenu?: () => void
  showMenuButton?: boolean
  Actions?: ComponentType
}

const DefaultActions = () => (
  <>
    <DesktopNavBar
      className="mr-4"
    />
    <div display="flex <mobile:hidden">
      <Button m="auto" className="whitespace-nowrap dark:bg-rh-navy-light dark:text-black">
        Sign Up
      </Button>
    </div>
    <IconButton
      variant="transparent"
      color="navy"
      m="l-4"
      className="dark:text-white"
      icon={mdiCart}
    />
  </>
)

const PageTitleBar = ({ onOpenMenu, showMenuButton = true, Actions = DefaultActions }: Props) => {
  return (
    <div bg="white dark:dark-900" p="x-4" h="22" display="flex" pos="sticky top-0" z="9">
      {showMenuButton && (
        <IconButton
          variant="transparent"
          m="r-4"
          display="desktop:hidden"
          icon={mdiMenu}
          onClick={onOpenMenu}
          className="!text-rh-navy !dark:text-white"
        />
      )}
      <div display="flex" m="desktop:l-16">
        <img
          src="/images/full_logo.svg"
          alt="Team RH Logo"
          display="<desktop:hidden"
          h="5"
          m="auto"
          className="filter dark:grayscale-100 dark:brightness-500"
        />
        <img
          src="/images/small_logo_dark.svg"
          alt="Team RH Logo"
          display="desktop:hidden"
          h="8"
          m="auto"
          className="filter dark:grayscale-100 dark:brightness-500"
        />
      </div>
      <div flex="1" />
      <Actions />
    </div>
  )
}

export default PageTitleBar
