import { mdiCart, mdiClose } from '@mdi/js'
import { NAV_BAR_ITEMS } from 'Helpers/nav-bar-items'
import Link from 'next/link'
import IconButton from 'WindiComponents/IconButton'
import Typography from 'WindiComponents/Typography'

interface Props {
  onCloseMenu?: () => void
  className?: string
}

const MobileNavModal = ({ onCloseMenu, className }: Props) => (
  <div
    bg="white dark:black"
    text="black dark:white"
    pos="fixed left-0 top-0 right-0 bottom-0"
    className={className}
  >
    <div h="full" p="4" m="t-3">
      <div display="flex">
        <IconButton
          variant="transparent"
          className="!text-rh-navy !dark:text-white"
          icon={mdiClose}
          onClick={onCloseMenu}
        />
        <div flex="1" />
        <IconButton
          variant="transparent"
          className="!text-rh-navy !dark:text-white"
          icon={mdiCart}
        />
      </div>
      <div pos="fixed left-0 top-0 right-0 bottom-0" display="flex" className="pointer-events-none">
        <div m="auto">
          {NAV_BAR_ITEMS.map(({ key, name, link }) => (
            <Link key={key} href={link} passHref>
              <a display="block" className="pointer-events-auto">
                <div display="flex" h="full" p="4" border="b-1 cool-gray-200 dark:dark-200">
                  <Typography m="auto" variant="body" font="medium">
                    {name}
                  </Typography>
                </div>
              </a>
            </Link>
          ))}
        </div>
      </div>
    </div>
  </div>
)

export default MobileNavModal
