import { ComponentType, ReactNode, useState } from 'react'
import MobileNavModal from './MobileNavModal'
import PageTitleBar from './PageTitleBar'

interface Props {
  children: ReactNode
  TitleActions?: ComponentType
  showMenuButton?: boolean
  className?: string
}

const PageFrame = ({ children, className, TitleActions, showMenuButton }: Props) => {
  const [isMenuOpen, setMenuOpen] = useState(false)

  return (
    <div className={className}>
      {isMenuOpen && (
        <MobileNavModal
          onCloseMenu={() => setMenuOpen(false)}
        />
      )}
      {!isMenuOpen && (
        <>
          <PageTitleBar
            Actions={TitleActions}
            onOpenMenu={() => setMenuOpen(true)}
            showMenuButton={showMenuButton}
          />
          <div>
            {children}
          </div>
        </>
      )}
    </div>
  )
}

export default PageFrame
