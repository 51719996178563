import { NAV_BAR_ITEMS } from 'Helpers/nav-bar-items'
import { useRouter } from 'next/router'
import Link from 'next/link'
import Typography from 'WindiComponents/Typography'

interface Props {
  className?: string
}

const DesktopNavBar = ({ className }: Props) => {
  const { route } = useRouter()

  return (
    <div display="<tablet:hidden" text="black dark:white" className={className}>
      {NAV_BAR_ITEMS.map(({ key, name, link }) => (
        <Link key={key} href={link} passHref>
          <a display="inline-block" h="full">
            <div display="flex" h="full" p="4">
              <Typography m="auto" variant="body" font="medium">
                {name}
              </Typography>
            </div>
            {route.startsWith(link) && (
              <div pos="relative">
                <div pos="absolute bottom-0 left-0 right-0">
                  <div bg="rh-red" w="full" h="1" />
                </div>
              </div>
            )}
          </a>
        </Link>
      ))}
    </div>
  )
}

export default DesktopNavBar
